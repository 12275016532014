import React from "react";
import Countries from "components/Countries";
import Candidates from "components/Candidates";
import Genders from "components/Genders";
import AgeGroups from "components/AgeGroups";

const MobileCarousel = () => {
  return (
    <React.Fragment>
      <div key="first" className="panel">
        <h5 className=" hidden-xs">We welcome</h5>
        <Candidates />
      </div>

      <div key="second" className="panel">
        <div className="row">
          <div className="col-sm-6 col-xs-12">
            <h5 className="age">Ages</h5>
            <AgeGroups />
          </div>
        </div>
      </div>

      <div className="panel" key="third">
        <div className="row">
          <div className="col-sm-6  col-xs-12">
            <h5 className="gender">Genders</h5>
            <Genders />
          </div>
        </div>
      </div>

      <div className="panel europe" key="forth">
        <h5>Total signups per country</h5>
        <Countries />
      </div>
    </React.Fragment>
  );
};

export default MobileCarousel;
