import React from "react";

import Candidates from "components/Candidates";
import AgeGroups from "components/AgeGroups";
import Genders from "components/Genders";
import Countries from "components/Countries";

/* Not using Fragment because `react-slick` does not recognize the TAG and does not render as multiple children */
const FireFoxCarousel = () => {
  return [
    <div key="second">
      <div className="row relativeness">
        <div className="col-sm-6 col-xs-12">
          <h5 className="age">Ages</h5>
          <div className="high-charts-wrapper">
            <AgeGroups />
          </div>
        </div>
        <div className="col-sm-6  col-xs-12">
          <h5 className="gender">Genders</h5>
          <div className="high-charts-wrapper">
            <Genders />
          </div>
        </div>
      </div>
    </div>,
    <div key="first" className="carousel-fitter">
      <div className="carousel-info">
        <div className="row">
          <div className="col-xs-12">
            <div className="row">
              <div className="col-xs-4">
                <h3 className="carousel-title carousel-title__1">TIME</h3>
              </div>
              <div className="col-xs-4">
                <h3 className="carousel-title carousel-title__2">FROM</h3>
              </div>
              <div className="col-xs-4">
                <h3 className="carousel-title carousel-title__3">SITE</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Candidates />
    </div>,
    <div className="europe" key="third">
      <h5>Total signups per country</h5>
      <Countries />
    </div>
  ];
};

export default FireFoxCarousel;
