import React, { Component } from "react";
// import  from 'containers/AwesomeContainer';

import Clock from "react-live-clock";

// import AgeGroup from 'components/AgeGroups';
import SignUps from "components/SignUps";
import Photos from "components/Photos";
import Carousel from "components/Carousel";

class Awesome extends Component {
  componentDidMount = () => {
    const { resizeDashboard } = this.props;
    window.addEventListener("resize", resizeDashboard);
    resizeDashboard();
  };

  componentWillMount = () => {
    const { resizeDashboard } = this.props;
    window.addEventListener("resize", resizeDashboard);
  };

  render() {
    const { awesomeRef, scale } = this.props;

    return (
      <div
        className="container container-fluid dashboard fade-in"
        ref={awesomeRef}
        style={{
          transform: `scale(${scale})`
        }}
      >
        <div className="row center-xs">
          <div className="col-xs-12 col-sm-4">
            <div className="panel panel-padding">
              <SignUps />
            </div>
          </div>
          <div className="col-xs-12 col-sm-8">
            <div className="candidate-photos panel">
              <div className="row">
                <div className="col-xs-12 col-sm-9">
                  <h3 className="candidate-photos__title">NEWEST FACES</h3>
                </div>
                <div className="hidden-xs col-sm-3">
                  <div className="clock">
                    {/* TODO: Fix the clock */}
                    <Clock format={"HH:mm:ss"} ticking={true} />
                  </div>
                </div>
              </div>
              <Photos />
            </div>
            <div className="carousel-panel">
              <h3 className="carousel-panel__title">WE WELCOME</h3>
              <Carousel />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Awesome;
