import React from "react";
import PropTypes from "prop-types";
import Slider from "react-slick";

const DesktopCarousel = ({ settings, play, pause, children, sliderRef }) => {
  return (
    <React.Fragment>
      <Slider {...settings} ref={sliderRef}>
        {children}
      </Slider>
      <div className="nav">
        <button id="playBut" className="nav-but nav-but__play" onClick={play} />
        <button
          id="pauseBut"
          className="nav-but nav-but__stop"
          onClick={pause}
        />
      </div>
    </React.Fragment>
  );
};

DesktopCarousel.propTypes = {
  play: PropTypes.func.isRequired,
  pause: PropTypes.func.isRequired,
  settings: PropTypes.object
};

export default DesktopCarousel;
