import firebase from "firebase/app";
import "firebase/database";

const stagingConfig = {
  apiKey: "AIzaSyAvwxAVE-5DjWPKGIpZkG8TY0F0CEIPuZ0",
  authDomain: "realtime-awesome.firebaseapp.com",
  databaseURL: "https://realtime-awesome.firebaseio.com",
  projectId: "realtime-awesome",
  storageBucket: "realtime-awesome.appspot.com",
  messagingSenderId: "435643022852"
  // databaseAuthVariableOverride: {
  //   uid: "verydifficulttoguessandimpossibletoremember"
  // }
};

const productionConfig = {
  apiKey: " AIzaSyBes1jfQu0z36I_wZ6_UAxa4grAYStof2A ",
  authDomain: "awesome-production.firebaseapp.com",
  databaseURL: "https://awesome-production.firebaseio.com",
  projectId: "awesome-production",
  storageBucket: "realtime-awesome.appspot.com",
  messagingSenderId: "737621331247"
  // databaseAuthVariableOverride: {
  //   uid: "verydifficulttoguessandimpossibletoremember"
  // }
};

const config =
  window.location.href.indexOf("realtime") === -1 &&
  window.location.href.indexOf("localhost") === -1
    ? productionConfig
    : stagingConfig;

firebase.initializeApp(config);

export default firebase;
