import React, { Component } from "react";
// import { throws } from "assert";

import firebase from "globals/firebase";
import Awesome from "components/pages/Awesome";
import Loader from "components/Loader";
import { handleSignUpSnapshot, formatSignUpNumbers } from "helpers/SignUp";

const AwesomeContext = React.createContext();

const SHOW_AWESOME_DELAY_MILLISECONDS = 3000;

export default class AwesomeContainer extends Component {
  state = {
    /* Data */
    signUpData: {
      totalToday: 0,
      totalWeek: 0,
      totalMonth: 0,
      totalYear: 0,
      totalAll: 0
    },
    ageGroupData: {},
    candidates: [],
    genders: [],
    countries: {},
    /* Others */
    scale: 1,
    isLoading: true,
    isFireFox: false,
    shouldRenderAwesome: false,
    locale: "nl",

    /* Sizes */
    windowWidth: 0,
    windowHeight: 0,
    isSmallScreen: 0
  };

  componentDidMount = () => {
    setTimeout(this.holdAwesomeRender, SHOW_AWESOME_DELAY_MILLISECONDS);
    const locale = 'nl';

    Promise.all([
      this.fetchSignUps({ locale }),
      this.fetchAgeGroup(),
      this.fetchCandidates(),
      this.fetchGenders(),
      this.fetchCountries()
    ]).then(() => {
      const isFireFox = /firefox/gi.test(navigator.userAgent);

      this.setState(
        {
          isFireFox,
          locale,
          isLoading: false
        },
        this.getWindowSizes()
      );
    });
  };

  holdAwesomeRender = () => this.setState({ shouldRenderAwesome: true });

  registerUpdateState = (field, value) => this.setState({ [field]: value });

  getWindowSizes = cb => {
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    const isSmallScreen = windowWidth < 768;

    this.setState({ windowWidth, windowHeight, isSmallScreen }, cb);
  };

  fetchSignUps = ({ locale }) => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("totals/per_day")
        .orderByKey()
        .on(
          "value",
          snapshot => {
            const signUpData = handleSignUpSnapshot(snapshot.val());
            const formattedSignUpData = formatSignUpNumbers(signUpData, locale);

            this.registerUpdateState("signUpData", formattedSignUpData);

            resolve(formattedSignUpData);
          },
          err => reject(err)
        );
    });
  };

  fetchCandidates = () => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("candidates")
        .orderByKey()
        .limitToLast(16)
        .on(
          "value",
          snapshot => {
            const keys = Object.keys(snapshot.val());
            const candidates = keys.map(function(v) {
              return snapshot.val()[v];
            });

            this.registerUpdateState("candidates", candidates);

            resolve(candidates);
          },
          err => reject(err)
        );
    });
  };

  fetchGenders = () => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("totals/genders")
        .on(
          "value",
          snapshot => {
            const rawData = snapshot.val();
            const newData = Object.entries(rawData).map(([key, value]) => [
              key,
              value
            ]);
            newData.unshift(["Genders", "Ammount"]);

            this.registerUpdateState("genders", newData);

            resolve(newData);
          },
          err => reject(err)
        );
    });
  };

  fetchAgeGroup = () => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("totals/age_groups")
        .on(
          "value",
          snapshot => {
            const rawData = snapshot.val();
            const newData = Object.entries(rawData).map(([key, value]) => [
              key,
              value
            ]);

            newData.unshift(["Age", "Ammount"]);

            this.registerUpdateState("ageGroupData", newData);

            resolve(newData);
          },
          err => reject(err)
        );
    });
  };

  fetchCountries = () => {
    return new Promise((resolve, reject) => {
      firebase
        .database()
        .ref("totals/countries")
        .on(
          "value",
          snapshot => {
            const countries = {
              nl: snapshot.val().nl,
              be: snapshot.val().be,
              se: snapshot.val().se,
              es: snapshot.val().es,
              at: snapshot.val().at,
              de: snapshot.val().de,
              fr: snapshot.val().fr,
              uk: snapshot.val().uk,
              ie: snapshot.val().ie,
              ch: snapshot.val().ch
            };

            this.registerUpdateState("countries", countries);

            resolve(countries);
          },
          err => reject(err)
        );
    });
  };

  turnOffLoader = () => this.setState({ isLoading: false });

  resizeDashboard = () => {
    this.getWindowSizes(() => {
      const { isSmallScreen, windowWidth, windowHeight } = this.state;
      if (!isSmallScreen) {
        const scalableDiv = this.awesomeRef;

        const contentWidth = scalableDiv.clientWidth;
        const contentHeight = scalableDiv.clientHeight;

        const scale = Math.min(
          windowWidth / contentWidth,
          windowHeight / contentHeight
        );

        /* Until here the DOM isn't complete to be able to measured.
          ForceUpdate rerender the DOM and allow us to pass a callback.
          If the proper dom, then we define the scales
        */

        /* Rounding the number to avoid blurry texts */
        const fixedScale = parseFloat(scale.toFixed(2));
        this.forceUpdate(() => this.setState({ scale: fixedScale }));
      }
    });
  };

  render() {
    const { isLoading, scale, shouldRenderAwesome, isFireFox } = this.state;

    return (
      <AwesomeContext.Provider value={{ ...this.state }}>
        {isLoading || !shouldRenderAwesome ? (
          <Loader isFireFox={isFireFox} />
        ) : (
          <Awesome
            awesomeRef={el => (this.awesomeRef = el)}
            scale={scale}
            resizeDashboard={this.resizeDashboard}
            key="awesome"
          />
        )}
      </AwesomeContext.Provider>
    );
  }
}

export const AwesomeConsumer = AwesomeContext.Consumer;
