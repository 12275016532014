import React from "react";
import classnames from "classnames";

const Country = ({ code, total }) => {
  return (
    <div className={classnames(code, "country-count")}>
      <span className="flag" />
      <span className="code visible-sm-block">{code}</span>
      <span className="country-total">{total}</span>
    </div>
  );
};

export default Country;
