import React from "react";
import { AwesomeConsumer } from "containers/AwesomeContainer";

import Candidate from "./components/Candidate";

const Candidates = () => {
  return (
    <AwesomeConsumer>
      {({ candidates }) => {
        const slicedCandidates = candidates.reverse().slice(0, 16);
        return (
          <div className="Candidates candidates">
            <table className="table table-striped">
              <tbody>
                {slicedCandidates.map((candidate, index) => (
                  <Candidate candidate={candidate} key={index} />
                ))}
              </tbody>
            </table>
          </div>
        );
      }}
    </AwesomeConsumer>
  );
};

export default Candidates;
