import React from "react";
import moment from "moment";

const Candidate = ({ candidate }) => {
  return (
    <tr>
      <td className="candidates__time">
        {moment(candidate.registered_at).format("HH:mm")}
      </td>
      <td className="candidates__name">{candidate.name}</td>
      <td className="candidates__from">
        <strong>{candidate.city}</strong>
      </td>
      <td className="candidates__site">
        {candidate.sites === undefined ? "YC" : candidate.sites[0].replace('_', ' ')}
      </td>
    </tr>
  );
};

export default Candidate;
