import React from "react";
import { AwesomeConsumer } from "containers/AwesomeContainer";
import { getPublicPath } from "helpers/string";

const YC_LOGO_PATH = getPublicPath("/images/youngcapital.svg");

const SignUps = () => (
  <AwesomeConsumer>
    {({ signUpData }) => {
      const {
        totalToday,
        totalWeek,
        totalMonth,
        totalYear,
        totalAll
      } = signUpData;

      return (
        <div id="signups" className="signups">
          <div className="row">
            <div className="col-xs-12 hidden-sm hidden-md hidden-lg">
              <img
                className="img-responsive"
                src={YC_LOGO_PATH}
                alt="YoungCapital"
              />
            </div>
          </div>
          <h3 className="total">TOTAL SIGNUPS</h3>
          <div className="number-block number-block__style">
            <div className="number-block__total">{totalAll}</div>
            <div className="number-block__orange">.</div>
          </div>
          <hr />
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="number">
                <div className="number-display number-block number-block-year">
                  {totalYear}
                </div>
                <h5 className="number-display number-h5">LAST 12 MONTHS</h5>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12">
              <div className="number">
                <div className="number-display number-block number-block-month">
                  {totalMonth}
                </div>
                <h5 className="number-display number-h5">LAST 31 DAYS</h5>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <div className="number">
                <div className="number-display number-block number-block-week">
                  {totalWeek}
                </div>
                <h5 className="number-display number-h5">LAST 7 DAYS</h5>
              </div>
            </div>

            <div className="col-xs-12 col-sm-12">
              <div className="number">
                <div className="number-display number-block number-block-day">
                  {totalToday}
                </div>
                <h5 className="number-display number-h5">TODAY</h5>
              </div>
            </div>
          </div>
          <div className="col-xs-12 hidden-xs">
            <img
              className="img-responsive img-responsive-float"
              src={YC_LOGO_PATH}
              alt="YoungCapital"
            />
          </div>
        </div>
      );
    }}
  </AwesomeConsumer>
);

export default SignUps;
