import React from "react";
import { AwesomeConsumer } from "containers/AwesomeContainer";
import Country from "./components/Country";
import { localeNumber, getPublicPath } from "helpers/string";

const Countries = () => {
  return (
    <AwesomeConsumer>
      {({ countries, locale }) => {
        return (
          <div className="country-carousel">
            <div className="europe__map">
              <div className="europe__map-img">
                <img
                  src={getPublicPath("/images/europe.svg")}
                  alt="Europe Map"
                />
              </div>
            </div>
            <div id="countries" className="countries">
              {Object.keys(countries).map(code => {
                const totalFormatted = localeNumber(countries[code], locale);
                return (
                  <Country key={code} code={code} total={totalFormatted} />
                );
              })}
            </div>
          </div>
        );
      }}
    </AwesomeConsumer>
  );
};

export default Countries;
