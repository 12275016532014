import React, { PureComponent } from "react";
import chunk from "lodash.chunk";

import { AwesomeConsumer } from "containers/AwesomeContainer";
import { calcAmountOfPhotos } from "helpers/Photos";

import Photo from "./components/Photo";

class Photos extends PureComponent {
  componentDidMount = () => {
    /* TODO: This code should be improved */
    setInterval(() => {
      let photoBoxes = document.querySelectorAll("div.transition-box");
      let visibleBoxes = [];
      const container = document.getElementsByClassName("photo-container")[0];
      photoBoxes.forEach(function(box) {
        if (
          box.offsetTop - container.offsetTop < 200 ||
          window.innerWidth > 768
        ) {
          visibleBoxes.push(box);
        }
      });
      let randomPhotoBox =
        visibleBoxes[Math.floor(Math.random() * visibleBoxes.length)];
      this.updateAnyPhotoCase(randomPhotoBox);
    }, 1000);
  };

  updateAnyPhotoCase = randomPhotoBox => {
    let random = Math.floor(Math.random() * 2);
    let replaceableImage = randomPhotoBox.getElementsByClassName("visible")[0];
    let replacingImage = randomPhotoBox.getElementsByClassName("invisible")[
      random
    ];
    replaceableImage.classList.add("invisible");
    replaceableImage.classList.remove("visible");
    replacingImage.classList.remove("invisible");
    replacingImage.classList.add("visible");
  };

  render() {
    return (
      <AwesomeConsumer>
        {({ windowWidth }) => {
          const amountOfPhotos = calcAmountOfPhotos(windowWidth);

          const groupedByThree = chunk(amountOfPhotos, 3);

          return (
            <div className="Photos">
              <div className="photo-container">
                {groupedByThree.map((value, index) => (
                  <Photo key={value + index} images={value} />
                ))}
              </div>
            </div>
          );
        }}
      </AwesomeConsumer>
    );
  }
}

export default Photos;
