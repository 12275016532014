import React from "react";

const Photo = ({ images }) => {
  return (
    <div className="transition-box">
      <img
        alt="candidate"
        className="photo invisible"
        src={`candidates/${images[0]}.png`}
      />
      <img
        alt="candidate"
        className="photo invisible"
        src={`candidates/${images[1]}.png`}
      />
      <img
        alt="candidate"
        className="photo visible"
        src={`candidates/${images[2]}.png`}
      />
    </div>
  );
};

export default Photo;
