import moment from "moment";
import { localeNumber } from "./string";

/* TODO: Should be refactored */
export const handleSignUpSnapshot = snapshot => {
  let totalToday = 0;
  let totalWeek = 0;
  let totalMonth = 0;
  let totalYear = 0;
  let totalAll = 0;

  Object.keys(snapshot).map(key => {
    let day = moment(key, "YYYYMMDD");
    let dailyAmount = parseInt(snapshot[key], 10);

    if (day.isSame(moment(), "day")) {
      totalToday += dailyAmount;
    }
    if (day.isSameOrAfter(moment().subtract(8, "days"))) {
      totalWeek += dailyAmount;
    }
    if (
      day.isSameOrAfter(
        moment()
          .subtract(1, "month")
          .subtract(1, "day")
      )
    ) {
      totalMonth += dailyAmount;
    }
    if (
      day.isSameOrAfter(
        moment()
          .subtract(1, "year")
          .subtract(1, "day")
      )
    ) {
      totalYear += dailyAmount;
    }
    totalAll += dailyAmount;
    return totalAll;
  });

  return { totalToday, totalWeek, totalMonth, totalYear, totalAll };
};

export const formatSignUpNumbers = (signUps, locale) => {
  return Object.entries(signUps).reduce((acc, [key, value]) => {
    acc[key] = localeNumber(value, locale);
    return acc;
  }, {});
};
