import React from "react";
import { getPublicPath } from "helpers/string";

const Loader = ({ isFireFox }) => {
  return (
    <div className="loader" id="loader">
      <div className="loader-container">
        {isFireFox ? (
          <video className="loader-container__logo" autoPlay loop>
            <source
              src={getPublicPath("/images/yc-glitch.mp4")}
              type="video/mp4"
            />
            <source
              src={getPublicPath("/images/yc-glitch.webm")}
              type="video/webm"
            />
          </video>
        ) : (
          <picture>
            <source
              srcSet={getPublicPath("/images/yc-glitch.webp")}
              type="image/webp"
            />
            <img
              className="loader-container__logo"
              srcSet={getPublicPath("/images/yc-glitch.gif")}
              alt="YoungCapital"
            />
          </picture>
        )}
        <h2 className="loader__text">Our international online network</h2>
      </div>
    </div>
  );
};

export default Loader;
