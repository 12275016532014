import React, { Component } from "react";
import classnames from "classnames";

import { AwesomeConsumer } from "containers/AwesomeContainer";

import MobileCarousel from "./components/MobileCarousel";
import DesktopCarousel from "./components/DesktopCarousel";
import FireFoxCarousel from "./components/FireFoxCarousel";
import OtherBrowsersCarousel from "./components/OtherBrowsersCarousel";

import "./carousel.scss";

class Carousel extends Component {
  state = {
    /* TODO: Implement it */
    isSliderRunning: true
  };

  play = () => {
    this.slider.slickPlay();
    let playLogo = document.getElementById("playBut");
    let pauseLogo = document.getElementById("pauseBut");

    /* TODO: Move this code to be a css class */
    playLogo.style.animation = "fadeout .5s ease";
    playLogo.style.opacity = 0;
    playLogo.style.zIndex = 0;

    pauseLogo.style.animation = "fadein .5s ease";
    pauseLogo.style.opacity = 1;
    pauseLogo.style.zIndex = 99;
  };

  pause = () => {
    this.slider.slickPause();
    let playLogo = document.getElementById("playBut");
    let pauseLogo = document.getElementById("pauseBut");

    pauseLogo.style.animation = "fadeout .5s ease";
    pauseLogo.style.opacity = 0;
    pauseLogo.style.zIndex = 0;

    playLogo.style.animation = "fadein .5s ease";
    playLogo.style.opacity = 1;
    playLogo.style.zIndex = 99;
  };

  render() {
    const sliderSettings = {
      dots: false,
      infinite: true,
      autoplaySpeed: 10000,
      autoplay: 3
    };

    return (
      <AwesomeConsumer>
        {({ isFireFox, isSmallScreen }) => {
          return (
            <div className={classnames("carousel", !isSmallScreen && "panel")}>
              {!isSmallScreen ? (
                <DesktopCarousel
                  play={this.play}
                  pause={this.pause}
                  settings={sliderSettings}
                  sliderRef={el => (this.slider = el)}
                >
                  {/* Invoking a function because of `react-slick` does not recognize as a component (??bug??) */}
                  {isFireFox ? FireFoxCarousel() : OtherBrowsersCarousel()}
                </DesktopCarousel>
              ) : (
                <MobileCarousel />
              )}
            </div>
          );
        }}
      </AwesomeConsumer>
    );
  }
}

export default Carousel;
// ReactDOM.render(<DemoCarousel />, document.getElementById("carousel"));
