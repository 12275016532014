import range from "lodash.range";

const sizes = {
  photosBigLg: range(108),
  photosBig: range(90),
  photosMedium: range(72),
  photosSmall: range(45),
  photosSmallXs: range(45),
  photosSmallXxs: range(36)
};

export const calcAmountOfPhotos = currentWidth => {
  if (currentWidth >= 1200) {
    return sizes.photosBigLg;
  } else if (currentWidth >= 0 && currentWidth <= 374) {
    return sizes.photosSmallXxs;
  } else if (currentWidth >= 375 && currentWidth <= 445) {
    return sizes.photosSmallXs;
  } else if (currentWidth >= 768 && currentWidth < 1023) {
    return sizes.photosBig;
  } else if (currentWidth >= 1024 && currentWidth <= 1200) {
    return sizes.photosBig;
  }

  // From 445px till 767px
  return sizes.photosSmall;
};
