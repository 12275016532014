import "@babel/polyfill";
import "nodelist-foreach-polyfill";
import "picturefill";

import React from "react";
import ReactDOM from "react-dom";

import "./index.scss";
import * as serviceWorker from "./registerServiceWorker";

import AwesomeContainer from "./containers/AwesomeContainer";

ReactDOM.render(<AwesomeContainer />, document.querySelector("#root"));

/* TODO: Do we want cache (offline)? */
serviceWorker.unregister();
