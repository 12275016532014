import React from "react";
import { Chart } from "react-google-charts";
import { formatters } from "helpers/chart";

import { AwesomeConsumer } from "containers/AwesomeContainer";

const pieOptions = {
  pieHole: 0,
  is3D: true,
  backgroundColor: "transparent",
  language: 'nl',
  slices: [
    {
      color: "#d63475"
    },
    {
      color: "#052fa3"
    },
    {
      color: "#54b8b1"
    },
    {
      color: "#e88531"
    }
  ],
  legend: {
    position: "bottom",
    alignment: "center",
    textStyle: {
      color: "#fff",
      fontSize: 10
    },
    maxLines: 1,
    rows: 1
  },
  tooltip: {
    trigger: "",
    showColorCode: true
  },
  chartArea: {
    left: 0,
    top: 0,
    width: "100%",
    height: "65%"
  },
  fontName: "Work Sans",
  fontSize: 13
  // enableInteractivity: false
};

const AgeGroups = () => {
  return (
    <AwesomeConsumer>
      {({ ageGroupData }) => {
        return (
          <div id="age-groups" className="chart-size">
            <Chart
              chartType="PieChart"
              data={ageGroupData}
              options={pieOptions}
              formatters={formatters}
              graph_id="PieChart"
              height="330px"
            />
          </div>
        );
      }}
    </AwesomeConsumer>
  );
};

export default AgeGroups;
